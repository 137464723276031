import { useState, useCallback } from "react";
import { getToken } from "../../utils/auth";
import routes from "../../routes";
import { customerApi } from "../../api/api";

const initialState = {
  customerCode: "",
  customerName: "",
  customerAdress: "",
  customerPhoneNumber1: "",
  customerPhoneNumber2: "",
  customerEmploeeName: "",
  customerEmploeePosition: "",
  asonicLicense: "",
  middleserverId: "",
  middleserverPassword: "",
  url: "",
  asonicPreServerIpOne: "",
  asonicNewServerIpOne: "",
  asonicNewServerPortOne: "",
  asonicPreServerIpTwo: "",
  asonicNewServerIpTwo: "",
  asonicNewServerPortTwo: ""
};

export default () => {
  const [info, setInfo] = useState(initialState);

  const handleInfo = useCallback(event => {
    const result = {};
    const {
      target: { id: key }
    } = event;
    result[key] = event.target.value;
    setInfo(state => Object.assign(state, result));
  }, []);

  const handleInfoFromList = useCallback(info => {
    setInfo(info);
  }, []);

  const submitCustomerInfo = useCallback(
    push => async event => {
      event.preventDefault();
      const token = getToken();
      if (token) {
        await customerApi.add(info, token);
        push(routes.customerLists);
      } else {
        push(routes.login);
      }
    },
    [info]
  );

  const submitCustomerInfoEdit = useCallback(
    push => event => {
      event.preventDefault();
      const token = getToken();
      if (token) {
        customerApi.edit(info, token);
        push(routes.customerLists);
      } else {
        push(routes.login);
      }
    },
    [info]
  );

  return {
    info,
    handleInfo,
    submitCustomerInfo,
    submitCustomerInfoEdit,
    handleInfoFromList,
    initialState
  };
};
