import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
    ${reset}
    * {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        box-sizing: border-box;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    html, body {
        background-color: #fbf9fc;
        #root {
        padding-top: 5%;
        @media (max-width: 900px) {
            padding-top: 8%;
         }
         @media (max-width: 600px)  {
            padding-top: 13%;
         }
         @media (max-width: 599px)  {
            padding-top: 17%;
         }
         @media (max-width: 400px)  {
            padding-top: 19%;
         }
        width: 100%;
        height: 100%;
        }
        width: 100%;
        height: 100%;
    }
`;

export default GlobalStyles;
