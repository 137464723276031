import React from "react";
import PropTypes from "prop-types";
import { FormInputContainer, FormInput, Caption } from "../styled";
import Input from "../../../hooks/Input";
import isTrueRef from "../../../hooks/isTrueRef";
import routes from "../../../routes";
const FormInputPresenter = (props) => {
  const { item, pathname, customerInfo, handleInfo } = props;
  const { value, handleValue } = Input();
  const IsTrue = isTrueRef();
  return (
    <FormInputContainer>
      <Caption isValue={customerInfo[Object.keys(item)[0]] || value}>
        {Object.values(item)[0]}
      </Caption>
      <FormInput
        value={
          !IsTrue.current &&
          !value &&
          customerInfo.customerCode &&
          pathname !== routes.addCustomer
            ? customerInfo[Object.keys(item)[0]] || ""
            : value || ""
        }
        disabled={
          pathname !== routes.addCustomer &&
          Object.values(item)[0] === "고객사 코드"
            ? true
            : false
        }
        onChange={(event) => {
          IsTrue.current = true;
          handleValue(event);
          handleInfo(event);
        }}
        id={Object.keys(item)[0]}
        placeholder={Object.values(item)[0]}
        required={
          !value &&
          (Object.values(item)[0] === "고객사 코드" ||
            Object.values(item)[0] === "고객사 명")
            ? true
            : false
        }
      />
    </FormInputContainer>
  );
};

FormInputPresenter.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  handleInfo: PropTypes.func.isRequired,
  customerInfo: PropTypes.object,
};

export default FormInputPresenter;
