import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: ${props => (props.openDialog ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div`
  min-width: 230px;
  text-align: center;
  width: 40%;
  height: 40%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
`;

export const IconContainer = styled.div`
  color: #facca1;
  height: max-content;
  width: max-content;
  padding: 10px 25px 10px 25px;
  display: flex;
  align-items: center;
  border: 2px solid #facca1;
  border-radius: 50%;
`;

export const Title = styled.h1`
  font-family: "Jua", sans-serif;
  min-width: 200px;
  font-size: 20px;
  color: #8b8b8b;
`;

export const BtnContainer = styled.div`
  display: flex;
  min-width: 120px;
  width: 20%;
  justify-content: space-between;
`;

export const Btn = styled.div`
  font-family: "Jua", sans-serif;
  text-align: center;
  cursor: pointer;
  background-color: ${props =>
    props.type === "cancle" ? "#2098D1" : "#ED5650"};
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  color: white;
  :hover {
    background-color: #2c3e50;
  }
`;
