// Type
export const SET_CUSTOMER_INFO = "CUSTOMER_STATE_REDUCER/SET_CUSTOMER_INFO";

const initialState = {
  customerCode: "",
  customerName: "",
  customerAdress: "",
  customerPhoneNumber1: "",
  customerPhoneNumber2: "",
  customerEmploeeName: "",
  customerEmploeePosition: "",
  asonicLicense: "",
  middleserverId: "",
  middleserverPassword: "",
  url: "",
  asonicPreServerIpOne: "",
  asonicNewServerIpOne: "",
  asonicNewServerPortOne: "",
  asonicPreServerIpTwo: "",
  asonicNewServerIpTwo: "",
  asonicNewServerPortTwo: ""
};

export const setCustomerInfo = customerInfo => {
  return {
    type: SET_CUSTOMER_INFO,
    customerInfo
  };
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_INFO:
      return {
        ...state,
        ...action.customerInfo
      };
    default:
      return state;
  }
};

export default customerReducer;
