import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/reducers/signInReducer/signInReducer";
import Login from "../../components/Login";
import stateManager from "./stateManager";
import { globalApi } from "../../api";
import { tokenGenerator, jwtDecode } from "../../utils/auth";
import routes from "../../routes";
import isToken from "../../hooks/isToken";

const LoginContainer = (props) => {
  const { token, setToken } = isToken();
  const newProps = stateManager();
  const dispatch = useDispatch();
  const setUser = useCallback((userInfo) => dispatch(setUserInfo(userInfo)), [
    dispatch,
  ]);
  const userInfo = useSelector((state) => state.signInReducer);
  const handleLogin = (id, password) => async (event) => {
    event.preventDefault();
    let token;
    const reqToken = tokenGenerator({ id, password });
    try {
      ({ data: token } = await globalApi.login(reqToken));
    } catch (error) {
      console.log(error);
    } finally {
      if (token) {
        let result = jwtDecode(token);
        if (result) {
          setUser(result);
          localStorage.setItem("token", token);
        }
      } else {
        alert("Id 혹은 password가 잘못되었습니다");
      }
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (!userInfo.id && token) {
      let result = jwtDecode(token);
      if (result) {
        setUser(result);
      }
    }
    if (token && userInfo.id) {
      props.history.push(routes.customerLists);
    }
  }, [userInfo, props.history, setUser, token, setToken]);

  return (
    <Login
      {...props}
      {...newProps}
      handleLogin={handleLogin}
      userInfo={userInfo}
      token={token}
    />
  );
};

export default LoginContainer;
