import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  DialogContainer,
  IconContainer,
  Title,
  BtnContainer,
  Btn
} from "./styled";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

const ConfirmDialog = props => {
  const {
    title,
    cancle,
    confirm,
    handleConfirm,
    data,
    handleCancle,
    openDialog
  } = props;
  return (
    <Container openDialog={openDialog}>
      <DialogContainer>
        <IconContainer>
          <FontAwesomeIcon icon={faExclamation} size="3x" />
        </IconContainer>
        <Title>{title}</Title>
        <BtnContainer>
          <Btn onClick={() => handleCancle(false)} type="cancle">
            {cancle}
          </Btn>
          <Btn
            onClick={() => {
              handleConfirm(data);
              handleCancle(false);
            }}
            type="confirm"
          >
            {confirm}
          </Btn>
        </BtnContainer>
      </DialogContainer>
    </Container>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  cancle: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  data: PropTypes.any,
  handleCancle: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired
};

export default ConfirmDialog;
