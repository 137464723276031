import jwt from "jsonwebtoken";
import dotenv from "dotenv";
import routes from "../../routes";
dotenv.config();

const TOKEN = "token";

export const tokenGenerator = payload =>
  jwt.sign(payload, process.env.REACT_APP_SECRET_KEY);

export const jwtDecode = token => {
  return jwt.verify(token, process.env.REACT_APP_SECRET_KEY, (err, decoded) => {
    if (err) {
      console.log(`${err.name} ${err.message}`);
    } else {
      return decoded;
    }
  });
};

export const IsLoggined = (history, userInfo) => {
  const token = localStorage.getItem(TOKEN);
  if (!token && !userInfo.id) {
    history.push(routes.login);
  } else if (!token && userInfo.id) {
    history.push(routes.login);
  }
};

export const getToken = () => localStorage.getItem(TOKEN);
