import React from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import uiString from "../../uiString.json";
import routes from "../../routes.js";

const TitleHelmet = ({ mainTitle, title }) => {
  let PAGE_TITLE;
  const parseTitle = `/${title.split("/")[1]}`;
  switch (parseTitle) {
    case routes.home:
      PAGE_TITLE = uiString.title.login;
      break;
    case routes.customerLists:
      PAGE_TITLE = uiString.title.lists;
      break;
    case routes.addCustomer:
      PAGE_TITLE = uiString.title.add;
      break;
    case `/${routes.editCustomer().split("/")[1]}`:
      PAGE_TITLE = uiString.title.edit;
      break;
    default:
      break;
  }

  return (
    <Helmet>
      <title>{`${mainTitle} | ${PAGE_TITLE}`}</title>
    </Helmet>
  );
};

TitleHelmet.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default TitleHelmet;
