import styled from "styled-components";
import routes from "../../routes";

export const Container = styled.div`
  background-color: #fbf9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 567px) {
    padding-bottom: 75%;
  }
  font-family: "Noto Sans KR", sans-serif;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #777777;
`;

export const FormContainer = styled.form`
  margin-top: 40px;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #d7d7d7;
  margin-bottom: 20px;
`;

export const Caption = styled.div`
  transition: all 0.2s ease-in-out;
  top: ${props => (props.isValue ? "5%" : "40%")};
  opacity: ${props => (props.isValue ? "1" : "0")};
  visibility: ${props => (props.isValue ? "visible" : "hidden")};
  left: 23px;
  background-color: white;
  position: absolute;
  color: ${props => (props.isValue ? "#3498db" : "none")};
`;

export const FormInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  :last-child {
    border-radius: 0 0 10px 10px;
  }
  padding: 10px;
  :hover {
    ${Caption} {
      top: 5%;
      color: #3498db;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const FormInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  ::placeholder {
    font-weight: bold;
  }
  ::-webkit-input-placeholder {
    font-weight: bold;
  }
  border: 1px solid #d7d7d7;
  padding: 15px;
  box-sizing: border-box;
`;

export const SubmitButton = styled.button`
  position: fixed;
  bottom: 10%;
  right: 2%;
  outline: none;
  border: none;
  height: 6%;
  cursor: pointer;
  text-align: center;
  @media (max-width: 500px) {
    min-width: 20%;
    padding: 5px 0 5px 0;
    width: 20%;
    right: 3%;
  }
  min-width: 80px;
  width: 8%;
  min-height: 35px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${props =>
    props.pathname === routes.addCustomer ? "#a787ff" : "#EF4748"};
  color: white;
  -webkit-box-shadow: 2px 10px 17px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 17px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 10px 17px -6px rgba(0, 0, 0, 0.75);
  :hover {
    background-color: #34495e;
  }
  transform: translateY(-3px);
  :active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transform: translateY(0px);
    transition: all 0.1s;
  }
  margin-right: 10%;
`;
