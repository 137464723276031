import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import EditCustomer from "../../components/EditCustomer";
import { IsLoggined } from "../../utils/auth";
import customerInfomation from "../../hooks/customerInformation";
import routes from "../../routes";
const EditCustomerContainer = props => {
  const {
    location: { pathname }
  } = props;
  const userInfo = useSelector(state => state.signInReducer);
  const customerInfo = useSelector(state => state.customerReducer);
  const customerState = customerInfomation();
  const category = [
    { customerCode: "고객사 코드" },
    { customerName: "고객사 명" },
    { customerAdress: "고객사 주소" },
    { customerPhoneNumber1: "고객사 전화번호1" },
    { customerPhoneNumber2: "고객사 전화번호1" },
    { customerEmploeeName: "고객사 직원 명" },
    { customerEmploeePosition: "고객사 직원 직책" },
    { asonicLicense: "A-Sonic라이센스" },
    { middleserverId: "중간서버 접속 아이디" },
    { middleserverPassword: "중간서버 접속 패스워드" },
    { url: "URL" },
    { asonicPreServerIpOne: "A-Sonic 이전 서버아이피_One" },
    { asonicNewServerIpOne: "A-Sonic 신규 서버아이피_One" },
    { asonicNewServerPortOne: "A-Sonic 신규 서버포트_One" },
    { asonicPreServerIpTwo: "A-Sonic 이전 서버아이피_Two" },
    { asonicNewServerIpTwo: "A-Sonic 신규 서버아이피_Two" },
    { asonicNewServerPortTwo: "A-Sonic 신규 서버포트_Two" }
  ];

  useEffect(() => {
    if (props.history.location.pathname !== routes.addCustomer) {
      customerState.handleInfoFromList(customerInfo);
    } else if (props.history.location.pathname === routes.addCustomer) {
      customerState.handleInfoFromList(customerState.initialState);
    }
  }, [customerInfo, customerState, props.history.location]);

  useEffect(() => {
    IsLoggined(props.history, userInfo);
  }, [userInfo, props.history]);
  return (
    <EditCustomer
      {...props}
      category={category}
      pathname={pathname}
      {...customerState}
      customerInfo={customerInfo}
    />
  );
};

export default EditCustomerContainer;
