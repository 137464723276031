import { combineReducers } from "redux";
import signInReducer from "./signInReducer";
import customerReducer from "./custoemrReducer/customerReducer";

const reducers = combineReducers({
  signInReducer,
  customerReducer
});

export default reducers;
