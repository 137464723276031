import React from "react";
import ConfirmDialogContainer from "../../containers/ConfirmDialogContainer";
import PropTypes from "prop-types";
import {
  Container,
  Title,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  EditBtn,
  DeleteBtn,
  BtnContainer
} from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader";
import routes from "../../routes";

const CustomerLists = props => {
  const {
    category,
    list,
    history: { push },
    editCustomerInfo,
    customerCode,
    item: selectedItem,
    handleDelete,
    IsClicked: { isClicked, setIsClicked }
  } = props;
  return (
    <>
      {list ? (
        <Container>
          <Title>고객사 정보</Title>
          <Table>
            <Thead>
              <Tr>
                {category.map((item, index) => (
                  <Th key={`${Object.keys(item)[0]} ${index}`}>
                    {Object.values(item)[0]}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {list &&
                list.map((item, number) => (
                  <Tr
                    onClick={editCustomerInfo(item, number)}
                    key={`${item} ${number}`}
                  >
                    {category.map((key, index) => (
                      <Td
                        key={`${key}, ${index}`}
                        selectedItem={selectedItem}
                        itemName={`${item.customerCode} ${number}`}
                      >
                        {item[Object.keys(key)[0]]}
                      </Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <BtnContainer>
            <EditBtn
              onClick={() => {
                const parsingId = encodeURIComponent(customerCode);
                customerCode && push(routes.editCustomer(parsingId));
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="lg" />
            </EditBtn>
            <DeleteBtn
              onClick={() => setIsClicked(customerCode ? true : false)}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </DeleteBtn>
          </BtnContainer>
          <ConfirmDialogContainer
            title="정말 삭제하시겠습니까?"
            cancle="취소"
            confirm="삭제"
            handleConfirm={handleDelete}
            data={customerCode}
            handleCancle={setIsClicked}
            openDialog={isClicked}
          />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

CustomerLists.propTypes = {
  category: PropTypes.array.isRequired,
  list: PropTypes.array,
  push: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  customerCode: PropTypes.string,
  selectedItem: PropTypes.string,
  handleDelete: PropTypes.func.isRequired
};

export default CustomerLists;
