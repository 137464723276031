import React, { useCallback, useEffect } from "react";
import App from "../../App";
import isToken from "../../hooks/isToken";
import { jwtDecode } from "../../utils/auth";
import routes from "../../routes";
import { setUserInfo } from "../../redux/reducers/signInReducer/signInReducer";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
const AppContainer = props => {
  const { token, setToken } = isToken();
  const dispatch = useDispatch();
  const setUser = useCallback(userInfo => dispatch(setUserInfo(userInfo)), [
    dispatch
  ]);
  const userInfo = useSelector(state => state.signInReducer);

  const checkToken = useCallback(() => {
    const isToken = localStorage.getItem("token");
    if (!isToken) {
      setToken(false);
      props.history.push(routes.login);
    }
  }, [setToken, props.history]);

  useEffect(() => {
    window.addEventListener("storage", checkToken);
    setToken(localStorage.getItem("token"));
    if (!userInfo.id && token) {
      let result = jwtDecode(token);
      if (result) {
        setUser(result);
      }
    }
    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, [userInfo, setUser, token, setToken, checkToken]);
  return <App {...props} isLoggined={userInfo.id && token ? true : false} />;
};

export default withRouter(AppContainer);
