// Type
export const SET_USER_INFO = "SIGNIN_STATE_REDUCER/SET_USER_INFO";

const initialState = {
  id: "",
  manager: ""
};

export const setUserInfo = userInfo => {
  return {
    type: SET_USER_INFO,
    userInfo
  };
};

const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.userInfo
      };
    default:
      return state;
  }
};

export default signInReducer;
