const HOME = "/";
const LOGIN = "/login";
const API = "/api";
const CUSTOMER = "/customer";
const CUSTOMER_LISTS = "/customer-lists";
const ADD_CUSTOMER = "/add-customer";
const EDIT_CUSTOMER = "/edit-customer/:id";
const DELETE_CUSTOMER = "/delete-customer/:id";

export default {
  home: HOME,
  login: LOGIN,
  customerLists: CUSTOMER_LISTS,
  addCustomer: ADD_CUSTOMER,
  editCustomer: id => {
    if (id) {
      return `/edit-customer/${id}`;
    }
    return EDIT_CUSTOMER;
  },
  api: API,
  customer: CUSTOMER,
  deleteCustomer: id => {
    if (id) {
      return `/delete-customer/${id}`;
    } else {
      return DELETE_CUSTOMER;
    }
  }
};
