import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Title,
  FormContainer,
  Description,
  InputForm,
  SubmitBtn
} from "./styled";
import Loader from "../Loader";

const Login = props => {
  const {
    value,
    handleValue,
    passwordValue,
    handlePasswordValue,
    handleLogin,
    userInfo,
    token
  } = props;
  return (
    <>
      {userInfo.id && token ? (
        <Loader />
      ) : (
        <Container>
          <Title>Asonic 중앙관리서버</Title>
          <FormContainer onSubmit={handleLogin(value, passwordValue)}>
            <Description htmlFor="login">여기서 로그인 해주세요</Description>
            <InputForm
              id="login"
              value={value}
              onChange={handleValue}
              type="text"
              placeholder="계정을 입력해주세요"
              required
            />
            <InputForm
              value={passwordValue}
              onChange={handlePasswordValue}
              type="password"
              placeholder="Password"
              required
            />
            <SubmitBtn>Asonic 로그인</SubmitBtn>
          </FormContainer>
        </Container>
      )}
    </>
  );
};

Login.propTypes = {
  value: PropTypes.string,
  passwordValue: PropTypes.string,
  handleValue: PropTypes.func.isRequired,
  handlePasswordValue: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  token: PropTypes.string
};

export default Login;
