import styled from "styled-components";
import { Link } from "react-router-dom";
import uiString from "../../uiString.json";

export const Container = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  bottom: auto;
  display: ${props => (props.isLoggined ? "flex" : "none")};
  justify-content: space-between;
  background-color: #fbf9fc;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Title = styled.div`
  background-color: #3b4250;
  @media (max-width: 500px) {
    min-width: 185px;
    width: 100%;
  }
  min-width: 210px;
  font-family: "Jua", sans-serif;
  font-size: 20px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  align-content: center;
  color: white;
  text-decoration: none;
`;

export const Menu = styled.div`
  @media (max-width: 500px) {
    min-width: 100px;
    width: 100%;
  }
  min-width: 180px;
  width: 30%;
  display: flex;
`;

export const MenuItem = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  text-decoration: none;
  :hover {
    background-color: #34495e;
  }
  background: ${props => {
    switch (props.type) {
      case uiString.menu.add:
        return "#A787FF";
      case uiString.menu.list:
        return "#3963D2";
      default: {
        return "#72E0A3";
      }
    }
  }};
`;
