import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const Title = styled.span`
  font-family: "Jua", sans-serif;
  justify-self: self-start;
  margin-top: 5%;
  flex: 1/2;
  font-size: 42px;
  @media (max-height: 700px) {
    font-size: 32px;
  }
  color: #72e0a3;
`;

export const FormContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  width: 20%;
`;

export const Description = styled.label`
  margin-bottom: 10px;
  font-size: 12px;
`;

export const InputForm = styled.input`
  border: 2px solid #72e0a3;
  font-weight: bold;
  border-radius: 10px;
  padding: 8px;
  outline: none;
  margin-bottom: 10%;
`;

export const SubmitBtn = styled.button`
  border: none;
  outline: none;
  background-color: #72e0a3;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: white;
  transition: box-shadow ease-in-out 0.2s, transform ease-in-out 0.2s;
  :hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
  }
  :active {
    box-shadow: none;
    transform: translateY(0px);
    transition: all 0.1s;
  }
  user-select: none;
  font-family: "Noto Sans KR", sans-serif;
  margin-bottom: 40%;
`;
