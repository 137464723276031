import React from "react";
import Proptypes from "prop-types";
import { Container, Title, FormContainer, SubmitButton } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import routes from "../../routes";
import FormInputPresenter from "./FormInputPresenter";

const EditCustomer = props => {
  const {
    category,
    pathname,
    handleInfo,
    submitCustomerInfo,
    submitCustomerInfoEdit,
    history: { push },
    customerInfo
  } = props;
  return (
    <>
      <Container>
        <Title>정보 추가 및 수정</Title>
        <FormContainer
          method="post"
          onSubmit={
            pathname === routes.addCustomer
              ? submitCustomerInfo(push)
              : submitCustomerInfoEdit(push)
          }
        >
          {category.map((item, index) => {
            return (
              <FormInputPresenter
                key={`${item} ${index}`}
                item={item}
                pathname={pathname}
                customerInfo={customerInfo}
                handleInfo={handleInfo}
              />
            );
          })}
          <SubmitButton pathname={pathname}>
            {pathname === routes.addCustomer ? (
              <FontAwesomeIcon icon={faPlus} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faEdit} size="lg" />
            )}
          </SubmitButton>
        </FormContainer>
      </Container>
    </>
  );
};

EditCustomer.propTypes = {
  pathname: Proptypes.string.isRequired,
  category: Proptypes.array.isRequired,
  handleInfo: Proptypes.func.isRequired,
  customerInfo: Proptypes.object.isRequired
};

export default EditCustomer;
