import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Title, MenuItem, Menu } from "./styled";
import uiString from "../../uiString.json";
import routes from "../../routes";

const Header = props => {
  const {
    location: { pathname },
    isLoggined,
    handleLogout
  } = props;
  return (
    <Container isLoggined={isLoggined}>
      <Title>{uiString.title.mainTitle}</Title>
      <Menu>
        <MenuItem
          to={
            pathname === routes.addCustomer
              ? routes.customerLists
              : routes.addCustomer
          }
          type={
            pathname === routes.addCustomer
              ? uiString.menu.list
              : uiString.menu.add
          }
        >
          {pathname === routes.addCustomer
            ? uiString.menu.list
            : uiString.menu.add}
        </MenuItem>
        <MenuItem onClick={handleLogout} to={routes.login}>
          {uiString.menu.logout}
        </MenuItem>
      </Menu>
    </Container>
  );
};

Header.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  isLoggined: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired
};

export default withRouter(Header);
