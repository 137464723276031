import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  background-color: #fbf9fc;
`;

export const Title = styled.div`
  background-color: #fbf9fc;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: #777777;
`;

export const Table = styled.table`
  display: block;
  overflow: auto;
  height: 100%;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  padding-bottom: 20px;
  text-align: center;
  margin-top: 10px;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #c4c4c4;
`;

export const Th = styled.th`
  min-width: 170px;
  font-weight: bold;
  padding: 20px;
  background-color: #3b4250;
  color: white;
`;

export const Thead = styled.thead`
  background-color: #fbf9fc;
`;

export const Td = styled.td`
  padding: 20px;
  background-color: ${props => {
    const { selectedItem, itemName } = props;
    if (selectedItem === itemName) {
      return "#C1FFC1";
    } else {
      return "white";
    }
  }};
  border: 1px solid #c4c4c4;
`;

export const Tbody = styled.tbody`
  padding: 5px;
  ${Tr} {
    cursor: pointer;
    :hover {
      ${Td} {
        background-color: #90acc3;
        color: white;
      }
    }
  }
`;

export const EditBtn = styled.div`
  height: 50px;
  width: 50px;
  background-color: #ef4748;
`;

export const DeleteBtn = styled.div`
  height: 50px;
  margin-top: 6px;
  width: 50px;
  background-color: #4b7fde;
`;

export const BtnContainer = styled.div`
  position: fixed;
  color: white;
  height: 110px;
  width: max-content;
  right: 5%;
  bottom: 10%;
  text-align: center;
  cursor: pointer;
  ${EditBtn}, ${DeleteBtn} {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow ease-in-out 0.1s, transform ease-in-out 0.1s;
    :hover {
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
      background-color: #34495e;
      transform: translateY(-5px);
    }
    :active {
      box-shadow: none;
      transform: translateY(0px);
    }
  }
`;
