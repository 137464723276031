import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import EditCustomerContainer from "./containers/EditCustomerContainer/EditCustomerContainer";
import CustomerListsContainer from "./containers/CustomerListsContainer";
import LoginContainer from "./containers/LoginContainer";
import GlobalStyles from "./GlobalStyles";
import routes from "./routes";
import { HelmetProvider } from "react-helmet-async";
import TitleHelmet from "./components/TitleHelmet";
import uiString from "./uiString.json";
import HeaderContainer from "./containers/HeaderContainer";

function App(props) {
  const {
    location: { pathname },
    isLoggined
  } = props;
  return (
    <>
      <HelmetProvider>
        <TitleHelmet mainTitle={uiString.title.mainTitle} title={pathname} />
      </HelmetProvider>
      <HeaderContainer isLoggined={isLoggined} />
      <Switch>
        <Route exact path={routes.home} component={LoginContainer} />
        <Route path={routes.addCustomer} component={EditCustomerContainer} />
        <Route path={routes.editCustomer()} component={EditCustomerContainer} />
        <Route path={routes.customerLists} component={CustomerListsContainer} />
        <Redirect
          from="*"
          to={isLoggined ? routes.customerLists : routes.home}
        />
      </Switch>
      <GlobalStyles />
    </>
  );
}

App.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  isLoggined: PropTypes.bool.isRequired
};

export default App;
