import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/reducers/signInReducer/signInReducer";
import Header from "../../components/Header";
const HeaderContainer = props => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    const TOKEN = "token";
    const logOutFormat = {
      id: "",
      manager: ""
    };
    localStorage.removeItem(TOKEN);
    dispatch(setUserInfo(logOutFormat));
  }, [dispatch]);

  return <Header {...props} handleLogout={handleLogout} />;
};

export default HeaderContainer;
