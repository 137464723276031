import axios from "axios";
import routes from "../routes";
import dotenv from "dotenv";
import https from "https";

dotenv.config();

const agent = new https.Agent({
  rejectUnauthorized: false
});

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL
      : "https://bridge.a-sonic.cloud",
  headers: { Pragma: "no-cache" },
  httpsAgent: agent
});

export const globalApi = {
  login: reqToken =>
    api.post(routes.login, {
      reqToken
    })
};

export const customerApi = {
  add: (info, token) =>
    api.post(
      `${routes.api}${routes.addCustomer}`,
      {
        info
      },
      { headers: { token: token } }
    ),
  delete: (token, id) =>
    api.delete(`${routes.api}${routes.deleteCustomer(id)}`, {
      headers: { token: token }
    }),
  edit: (info, token) =>
    api.post(
      `${routes.api}${routes.editCustomer(info.customerCode)}`,
      {
        info
      },
      { headers: { token: token } }
    ),
  get: token =>
    api.get(`${routes.api}${routes.customer}`, {
      headers: { token: token }
    })
};
