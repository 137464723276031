import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerInfo } from "../../redux/reducers/custoemrReducer/customerReducer";
import CustomerLists from "../../components/CustomerLists";
import { IsLoggined, getToken } from "../../utils/auth";
import { customerApi } from "../../api/api";
import customerListHook from "../../hooks/customerListHook";
import selectedItemHook from "../../hooks/selectedItemHook";
import isClickedHook from "../../hooks/isClickedHook";
import IsTrue from "../../hooks/isTrue";

const CustomerListsContainer = props => {
  const userInfo = useSelector(state => state.signInReducer);
  const { customerCode } = useSelector(state => state.customerReducer);
  const dispatch = useDispatch();
  const { list, setList } = customerListHook();
  const { item, setItem } = selectedItemHook();
  const IsClicked = isClickedHook();
  const { isTrue, setIsTrue } = IsTrue();
  const category = [
    { customerCode: "고객사 코드" },
    { customerName: "고객사 명" },
    { customerAdress: "고객사 주소" },
    { customerPhoneNumber1: "고객사 전화번호1" },
    { customerPhoneNumber2: "고객사 전화번호2" },
    { customerEmploeeName: "고객사 직원 명" },
    { customerEmploeePosition: "고객사 직원 직책" },
    { asonicLicense: "A-Sonic라이센스" },
    { middleserverId: "중간서버 접속 아이디" },
    { middleserverPassword: "중간서버 접속 패스워드" },
    { url: "URL" },
    { asonicPreServerIpOne: "A-Sonic 이전 서버아이피_One" },
    { asonicNewServerIpOne: "A-Sonic 신규 서버아이피_One" },
    { asonicNewServerPortOne: "A-Sonic 신규 서버포트_One" },
    { asonicPreServerIpTwo: "A-Sonic 이전 서버아이피_Two" },
    { asonicNewServerIpTwo: "A-Sonic 신규 서버아이피_Two" },
    { asonicNewServerPortTwo: "A-Sonic 신규 서버포트_Two" }
  ];

  const getCustomerList = useCallback(async () => {
    const token = await getToken();
    customerApi
      .get(token)
      .then(info => {
        if (Array.isArray(info.data)) {
          setList(info.data);
        }
        return info;
      })
      .catch(error => {
        console.log(error);
        setList([]);
      });
  }, [setList]);

  const editCustomerInfo = useCallback(
    (customerInfo, number) => () => {
      dispatch(setCustomerInfo(customerInfo));
      setItem(`${customerInfo.customerCode} ${number}`);
    },
    [dispatch, setItem]
  );

  const handleDelete = useCallback(
    async customerCode => {
      if (customerCode) {
        const parsingId = encodeURIComponent(customerCode);
        const token = await getToken();
        customerApi
          .delete(token, parsingId)
          .then(() => {
            setIsTrue(true);
          })
          .catch(error => console.log(error));
      }
    },
    [setIsTrue]
  );

  useEffect(() => {
    if (isTrue) {
      setIsTrue(false);
      getCustomerList();
    }
  }, [isTrue, setIsTrue, getCustomerList]);

  useEffect(() => {
    // reducer를 초기화 하기 위해주기 위해 dispatch를 해준다.
    dispatch(
      setCustomerInfo({
        customerCode: "",
        customerName: "",
        customerAdress: "",
        customerPhoneNumber1: "",
        customerPhoneNumber2: "",
        customerEmploeeName: "",
        customerEmploeePosition: "",
        asonicLicense: "",
        middleserverId: "",
        middleserverPassword: "",
        url: "",
        asonicPreServerIpOne: "",
        asonicNewServerIpOne: "",
        asonicNewServerPortOne: "",
        asonicPreServerIpTwo: "",
        asonicNewServerIpTwo: "",
        asonicNewServerPortTwo: ""
      })
    );
    IsLoggined(props.history, userInfo);
    getCustomerList();
  }, [userInfo, props.history, getCustomerList, dispatch]);
  return (
    <CustomerLists
      {...props}
      category={category}
      list={list}
      editCustomerInfo={editCustomerInfo}
      customerCode={customerCode}
      item={item}
      handleDelete={handleDelete}
      IsClicked={IsClicked}
    />
  );
};

export default CustomerListsContainer;
